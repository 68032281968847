export default{
    data(){
        return{
            ar:{
                opinionMsg:'رأيك يهمنا',
                tellMSg:'من فضلك أخبرنا',
                textPlaceholder:'أخبرنا ما في بالك',
                buttonLabel:'ارسال',
                dialogMsgs:{
                    success:"تم ارسال تقيمك بنجاح :)"
                }
            }
        }
    }
}