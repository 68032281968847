export default{
    data(){
        return{
            en:{
                welcomeMsg:'Hello',
                dateLabel:'Date :',
                timeLabel:'Time :',
                waitingLabel:'Waiting Customers :',
                serviceLabel:'Service :',
                turnLabel:'Turn Number :',
                nameLabel:'Name :',
                phoneLabel:'Phone :',
                warninigLabel:`Turn Canceled After 4 Turns`,
                rateLabel:'Tell Us Your Opinion',
            }
        }
    }
}