export default{
    data(){
        return{
            ar:{
                headLabel:'!عفواً',
                notfoundLabel:'404 - Page not found',
                contentLabel:'.الصفحة التي تحاول الوصول اليها غير موجودة او غير متاحة الان',
                buttonLabel:'الذهاب الي الصفحة الرئيسية'
            }
        }
    }
}