import Vue from "vue";
import VueRouter from "vue-router";
import AddTurn from "../views/AddTurn.vue";
import Turn from "../views/Turn.vue";
import PreReservation from "../views/PreReservation.vue";
import Booking from "../views/Booking.vue";
import BookingList from "../views/BookingList.vue";
import Rating from "../views/Rating.vue";
import NotFound from "../views/NotFound.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/add-turn/:branchId",
    name: "AddTurn",
    component: AddTurn,
    props:true
  },
  {
    path: "/turn",
    name: "Turn",
    component:Turn
  },
  {
    path: "/pre-reservation/:UserId",
    name: "PreReservation",
    component:PreReservation,
    props:true
  },
  {
    path: "/booking",
    name: "Booking",
    component:Booking
  },
  {
    path: "/booking-list",
    name: "BookingList",
    component:BookingList
  },
  {
    path: '/rating/:reservationId',
    name: "Rating",
    component:Rating,
    props:true
  },
    // after handling all routes it comes last to not found page .
  {
    // path:'/not-found',
    path:'/:notfound(.*)',
    name:'NotFound',
    component:NotFound
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to ,from,savedPosition){
    if (savedPosition){
      return savedPosition
    }
    return {left:0,top:0}
  },
});

export default router;
