
import axios from 'axios';

axios.defaults.baseURL = 'https://web.queue.best';

// axios.defaults.baseURL = 'http://localhost:8000';




