<template>
    <div class="booking-list" :class="{'custom-view':!bookingList.length}">
        <div class="container">
            <div class="row">
                <div class="col-12 my-3">
                    <div class="phone-wrapper">
                        <div class="err-msgs p-1">
                            <small class="d-block text-danger" v-if="!$v.phone.required && $v.phone.$dirty">{{lang.errMsgs.phone.required}}</small>
                            <small class="d-block text-danger" v-else-if="!$v.phone.numeric && $v.phone.$dirty">
                            {{lang.errMsgs.phone.numeric}}
                            </small>
                            <small class="d-block text-danger" v-else-if="!$v.phone.minLen && $v.phone.$dirty">
                            {{lang.errMsgs.phone.minLen}}
                            </small>
                            <small class="d-block text-danger" v-else-if="!$v.phone.maxLen && $v.phone.$dirty">
                            {{lang.errMsgs.phone.maxLen}}
                            </small>
                        </div>
                        <div class="search-area">
                            <div class="input-wrapper">
                                <div class="icon-wrapper">
                                    <i class="pi pi-phone"></i>
                                </div>
                                <div class="custom-input-field">
                                    <input 
                                        v-model="phone"
                                        @change="$v.phone.$touch"
                                        :class="{'border-danger':$v.phone.$invalid && $v.phone.$dirty}"
                                        type="number" class="form-control" id="phone"
                                        autocomplete="tel"
                                        :placeholder="lang.inputPlaceholder" aria-label="phone" aria-describedby="phone"
                                    >
                                </div>
                            </div>
                            <div class="button-wrapper">
                                <button 
                                    :disabled="$v.$invalid"
                                    @click.prevent="getMyBookings"
                                    class="btn btn-primary"
                                    :class="{'no-drop':$v.$invalid}"
                                    type="submit" 
                                >
                                {{lang.submitLabel}}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>                    
            </div>
            <transition name="fade">
                <div class="row" v-if="emptyList">
                    <div class="col-12 my-3">
                        <div class="empty-list">
                            <p>{{lang.emptyMsg}}</p>
                            <a href="https://queue.best"  class="btn btn-primary">{{lang.goLabel}}</a>
                        </div>
                    </div>
                </div>
            </transition>
            <transition name="fade">
                <div class="row" v-if="bookingList.length">
                    <div class="col-xl-4 col-md-6 my-3" v-for="(booking,index) in bookingList" :key="index">
                        <div class="ticket-wrapper">
                            <div class="logo">
                                <div class="img-wrapper">
                                <img src="../assets/logo.png" alt="Logo Picture">
                                </div>
                            </div>
                            <div class="user-details" :class="{'ar':local=='ar'}">
                                <!-- <div><h4><span>{{lang.welcomeMsg}}</span><span>{{booking.name}}</span></h4></div> -->

                                <div><span><i class="pi pi-calendar"></i></span> <span>{{lang.dateLabel}}</span> <span> {{new Date (booking.date).toLocaleDateString()}}</span></div>
                                <div>
                                    <span><i class="pi pi-calendar"></i></span><span>{{lang.timeLabel}}</span>
                                    <span>{{lang.fromLabel}} ({{booking.startTime}})</span>
                                    <span> {{lang.toLabel}} ({{booking.endTime}})</span>
                                 </div>
                                <div><span><i class="pi pi-id-card"></i></span> <span>{{lang.idLabel}}</span><span> {{booking.id}}</span></div>
                                <div v-if="booking.bookingType==1"><span><i class="pi pi-paperclip"></i></span> <span>{{lang.typeLabel}}</span><span> {{lang.physicalLabel}}</span></div>
                                <div v-if="booking.bookingType==2"><span><i class="pi pi-paperclip"></i></span> <span>{{lang.typeLabel}}</span> <span> {{lang.onlineLabel}}</span></div>
                                <div><span><i class="pi pi-user"></i></span> <span>{{lang.nameLabel}}</span> <span> {{booking.name}}</span></div>
                                <div><span><i class="pi pi-phone"></i> </span><span>{{lang.phoneLabel}}</span> <span> {{booking.phone}}</span></div>
                            </div>
                        </div>
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>
<script>
import { required,maxLength,minLength,numeric} from 'vuelidate/lib/validators'
import en from '../assets/languages/Booking_en.js'
import ar from '../assets/languages/Booking_ar.js'
import axios from'axios'
export default {
    mixins:[en,ar],
    data(){
        return{
            bookingList:[],
            phone:'',
            emptyList:false,
            /////////
            lang:'',
            local:'',
        }
    },
    validations:{
        phone:{
            required,
            numeric,
            minLen:minLength(11),
            maxLen:maxLength(11)
        },
    },
    created(){
        this.getLanguage();
    },
    methods:{
        getLanguage(){
        localStorage.getItem('language')
        if(localStorage.getItem('language')=='en'){
            this.lang=this.en
            this.local='en'
        }
        else if(localStorage.getItem('language')=='ar'){
            this.lang=this.ar
            this.local='ar'
        }
        },
        getMyBookings(){
            this.emptyList=false;
            if(!this.$v.$invalid){
                let data={
                    phone:this.phone
                }
                axios
                .post("/api/getMyBookings",data
                )
                .then(res=>{
                    // console.log(res)
                    if(res.data.status){
                        this.$store.dispatch("addBookingList",res.data.bookings)
                        this.bookingList=this.$store.getters.get_booking_list;
                        if(!this.bookingList.length)
                            this.emptyList=true;
                    }
                })
                .catch(err=>{
                    console.log(err);
                })
            }
            else
                console.log("disable attribute is deleted from console mode")
        },
    },
}
</script>
<style lang="scss">
@mixin saw-tooth-vertical($width:30px, $bg:#052f42) {
  background-image:
    /* Top jagged */
    linear-gradient(135deg, $bg 50%, transparent 50%),
    linear-gradient(225deg, $bg 50%, transparent 50%),
    /* Bottom jagged */
    linear-gradient(45deg, $bg 50%, transparent 50%),
    linear-gradient(-45deg, $bg 50%, transparent 50%);
  background-position:
    /* Top jagged */
    top left, top left,
    /* Bottom jagged */
    bottom left, bottom left;
  background-size: $width $width;
  background-repeat: repeat-x;
}
.custom-view{
    display: flex;
    justify-content: center;
    align-items: center;
}
.booking-list{
    width: 100%;
    height:calc(100% - 82px);
    .phone-wrapper{
        background-color: white;
        padding:30px 10px;
        border-radius: 10px;
        .no-drop{
            cursor: no-drop;
        }
        .err-msgs{
            text-align: center;
        }
        .search-area{
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            div{
                margin: 10px 0px;
            }
            .input-wrapper{
                flex-grow: 1;
                display: flex;
                .icon-wrapper{
                    background-color: #007bff;
                    height: 38px;
                    width: 50px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: white;
                }
                .custom-input-field{
                    flex-grow: 1;
                    .form-control{
                        border-radius:0px;
                        &:focus{
                            outline: none !important;
                            box-shadow: none !important;
                        }
                    }
                }
            }
            .button-wrapper{
                flex-grow: 1;
                button{
                    width: 100%;
                    // display: block;
                    border-radius:0px !important;
                }
            }
        }
    }
    .empty-list{
        background-color: white;
        padding:30px 10px;
        border-radius: 15px;
        text-align: center
    }
    .ticket-wrapper{
        // @include saw-tooth-vertical();
        display: flex;
        align-items: center;
        flex-direction: column;
        border-radius: 15px;
        background-color: white;
        padding:30px 10px;
        .logo{
            width: 100%;
            height:100%;
            margin: 10px 0px;
            display: flex;
            justify-content: center;
            .img-wrapper{
                width: 150px;
                height: 150px;
                img{
                    border-radius:50%;
                width: 100%;
                height: 100%;
                }
            }
        }
        .user-details{
            width: 100%;
            height: 100%;
            text-align: center;
            margin-bottom:10px;
            div{
                display: flex;
                justify-content: center;
                flex-wrap: wrap;
                text-transform: capitalize;
                margin: 10px 0px;
                span{
                    margin: 0px 2px;
                    i{
                        margin: 0px 5px;
                    }
                }
            }
        }
        .ar{
            div{
                flex-direction: row-reverse;
            }
        }  
    }
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0
}
@media (max-width:426px) {
.custom-view{
    width: 100%;
    height:calc(100% - 56px);
}
.booking-list{
    height:calc(100% - 56px);
    h4,h6{
        span{
            font-size: 16px;
        }
    }
    span{
        // font-size: 12px;
    }
}
}
@media (min-width: 767.98px) {
.booking-list{
    .phone-wrapper{
        .search-area{
            .button-wrapper{
                flex-grow:0.2;
            }
        }
    }
}
}
</style>