export default{
    data(){
        return{
            en:{
                inputPlaceholder:'Enter Your Phone Number',
                submitLabel:'My Bookings',
                //////// Error Msgs
                errMsgs:{
                    phone:{
                        required:'Phone number is required',
                        numeric:'Phone number should be numbers only',
                        minLen:'Phone number should be at least 11 digit',
                        maxLen:`Phone number can't be more than 11 digit`,
                    },
                },
                ////////////////////
                emptyMsg:'Your Booking List Is Empty',
                goLabel:'Go To Home page',
                ///////////////////
                welcomeMsg:'Hello',
                dateLabel:'Date :',
                timeLabel:'Time :',
                fromLabel:'From',
                toLabel:'To',
                idLabel:'Booking ID :',
                typeLabel:'Booking Type :',
                physicalLabel:'Physical In Branch',
                onlineLabel:'Online On Zoom',
                nameLabel:'Name :',
                phoneLabel:'Phone :',
            }
        }
    }
}