<template>
  <div class="pre-reservation" v-if="show">
      <div class="form-wrapper">
          <div class="head-text">
            <h6>{{lang.headMsg}}</h6>
          </div>
          <div class="form" :class="{'ar':local=='ar'}">
            <div class="form-fields">
                <div class="form-group">
                  <label for="name">{{lang.nameLabel}}</label>
                  <input 
                    v-model="name"
                    @change="$v.name.$touch"
                    :class="{'border-danger':$v.name.$invalid && $v.name.$dirty}"
                    type="text" class="form-control" id="name"
                    autocomplete="name"
                  >
                  <div class="err-msgs p-1">
                    <small class="d-block text-danger" v-if="!$v.name.required && $v.name.$dirty">{{lang.errMsgs.name.required}}</small>
                    <small class="d-block text-danger" v-else-if="!$v.name.minLen && $v.name.$dirty">{{lang.errMsgs.name.minLen}}</small>
                    <small class="d-block text-danger" v-else-if="!$v.name.maxLen && $v.name.$dirty">{{lang.errMsgs.name.maxLen}}</small>
                  </div>
                </div>
                <div class="form-group">
                  <label for="phone">{{lang.phoneLabel}}</label>
                  <input 
                    v-model="phone"
                    @change="$v.phone.$touch"
                    :class="{'border-danger':$v.phone.$invalid && $v.phone.$dirty}"
                    type="number" class="form-control" id="phone"
                    autocomplete="tel"
                  >
                  <div class="err-msgs p-1">
                    <small class="d-block text-danger" v-if="!$v.phone.required && $v.phone.$dirty">{{lang.errMsgs.phone.required}}</small>
                    <small class="d-block text-danger" v-else-if="!$v.phone.numeric && $v.phone.$dirty">
                      {{lang.errMsgs.phone.numeric}}
                    </small>
                    <small class="d-block text-danger" v-else-if="!$v.phone.minLen && $v.phone.$dirty">
                      {{lang.errMsgs.phone.minLen}}
                    </small>
                    <small class="d-block text-danger" v-else-if="!$v.phone.maxLen && $v.phone.$dirty">
                      {{lang.errMsgs.phone.maxLen}}
                    </small>
                  </div>
                </div>
                <transition name="slide">
                  <div class="form-group" v-if="branches.length">
                    <label>{{lang.branchLabel}}</label>
                    <Dropdown 
                      v-model="selectedBranch"
                      @change="$v.selectedBranch.$touch"
                      :class="{'border-danger':$v.selectedBranch.$invalid && $v.selectedBranch.$dirty}"
                      :options="branches" optionLabel="name" :placeholder="lang.branchPlaceholder" 
                    />
                    <div class="err-msgs p-1">
                      <small class="d-block text-danger" v-if="!$v.selectedBranch.required && $v.selectedBranch.$dirty">
                        {{lang.errMsgs.selectedBranch.required}}</small>
                    </div>
                  </div>
                </transition>
                <transition name="slide">
                  <div class="form-group" v-if="services.length">
                    <label>{{lang.serviceLabel}}</label>
                      <Dropdown 
                        v-model="selectedService"
                        @change="$v.selectedService.$touch"
                        :class="{'border-danger':$v.selectedService.$invalid && $v.selectedService.$dirty}"
                        :options="services" optionLabel="name" :placeholder="lang.servicePlaceholder" 
                      />
                    <div class="err-msgs p-1">
                      <small class="d-block text-danger" v-if="!$v.selectedService.required && $v.selectedService.$dirty">
                        {{lang.errMsgs.selectedService.required}}</small>
                    </div>
                  </div>
                </transition>
                <transition name="slide">
                  <div class="form-group" v-if="services.length">
                      <label>{{lang.dateLabel}}</label>
                      <input 
                        v-model="date"
                        @change="$v.date.$touch"
                        :class="{'border-danger':$v.date.$invalid && $v.date.$dirty}"
                        class="form-control"
                        type="Date" 
                      >
                      <div class="err-msgs p-1">
                        <small class="d-block text-danger" v-if="!$v.date.required && $v.date.$dirty">{{lang.errMsgs.date.required}}</small>
                      </div>
                  </div>
                </transition>
                <transition name="slide">
                  <div class="form-group" v-if="appointments.length">
                    <label>{{lang.appointmentLabel}}</label>
                      <Dropdown 
                        v-model="selectedAppointment"
                        @change="$v.selectedAppointment.$touch"
                        :class="{'border-danger':$v.selectedAppointment.$invalid && $v.selectedAppointment.$dirty}"
                        :options="appointments" optionLabel="name" :placeholder="lang.appointmentPlaceholder" 
                      />
                      <div class="err-msgs p-1">
                        <small class="d-block text-danger" v-if="!$v.selectedAppointment.required && $v.selectedAppointment.$dirty">
                          {{lang.errMsgs.selectedAppointment.required}} </small>
                      </div>
                  </div>
                </transition>
                <div class="form-group">
                  <label>{{lang.appointmentTypeLabel}}</label>
                  <div class="radio-options-wrapper">
                    <div class="p-field-radiobutton" :class="{'border-danger':$v.appointmentType.$invalid && $v.appointmentType.$dirty}">
                        <RadioButton 
                        id="physical" name="appointmentType" value="1" 
                        v-model="appointmentType"
                        @change="$v.appointmentType.$touch"
                      />
                        <label for="physical">{{lang.appointmentType.physicalLabel}}</label>
                    </div>
                    <div class="p-field-radiobutton">
                        <RadioButton 
                          id="online" name="appointmentType" value="2"  
                          v-model="appointmentType" 
                          @change="$v.appointmentType.$touch"
                      />
                        <label for="online">{{lang.appointmentType.onlineLabel}}</label>
                    </div>
                  </div>
                  <div class="err-msgs p-1">
                    <small class="d-block text-danger" v-if="!$v.appointmentType.required && $v.appointmentType.$dirty">
                      {{lang.errMsgs.appointmentType.required}} </small>
                  </div>
                </div>
                <div class="form-group" v-if="schedule">
                  <div class="price">
                    <h6>{{lang.PriceLabel}} : <strong>{{schedule.price}} </strong> <small>{{schedule.currency}}</small></h6>
                  </div>
                </div>
                <div class="form-group">
                  <button
                    :disabled="$v.$invalid"
                    @click.prevent="addBooking"
                    class="btn btn-primary mt-2"
                    :class="{'no-drop':$v.$invalid}"
                    type="submit" 
                  >{{lang.buttonLabel}}</button>
                </div>
            </div>
            <div class="custom-class">
              <div class="img-wrapper">
                <img src="../assets/Forms.svg" alt="">
              </div>
            </div>
          </div>
      </div>
      <Dialog header="Not Available" :visible.sync="noService" :style="{width: '350px'}" :modal="true">
          <div class="confirmation-content" :class="{'ar-dialog':local=='ar'}">
              <div class="icon-wrapper">
                <i class="pi pi-exclamation-triangle" style="font-size: 2rem" />
              </div>
              <div class="text-wrapper">
                  <span>{{lang.dialogMsgs.noService}}</span>
              </div>
          </div>
          <template #footer>
              <Button :label="lang.dialogbtns.closeLabel" icon="pi pi-times" @click="closeNoServiceConfirmation" class="p-button-outlined p-button-danger"/>
              <Button :label="lang.dialogbtns.okLabel" icon="pi pi-check" @click="closeNoServiceConfirmation" class="p-button-outlined" autofocus />
          </template>
      </Dialog> 
      <Dialog header="Not Available" :visible.sync="noAppointments" :style="{width: '350px'}" :modal="true">
          <div class="confirmation-content" :class="{'ar-dialog':local=='ar'}">
              <div class="icon-wrapper">
                <i class="pi pi-exclamation-triangle" style="font-size: 2rem" />
              </div>
              <div class="text-wrapper">
                  <span>{{lang.dialogMsgs.noAppointments}}</span>
              </div>
          </div>
          <template #footer>
              <Button label="Close" icon="pi pi-times" @click="closeNoAppointmentsConfirmation" class="p-button-text"/>
              <Button label="Ok" icon="pi pi-check" @click="closeNoAppointmentsConfirmation" class="p-button-text" autofocus />
          </template>
      </Dialog> 
      <Dialog header="Not Available" :visible.sync="noPreReservation" :style="{width: '350px'}" :modal="true">
          <div class="confirmation-content" :class="{'ar-dialog':local=='ar'}">
              <div class="icon-wrapper">
                <i class="pi pi-exclamation-triangle" style="font-size: 2rem" />
              </div>
              <div class="text-wrapper">
                  <span>{{lang.dialogMsgs.noPreReservation}}</span>
              </div>
          </div>
          <template #footer>
              <Button label="Close" icon="pi pi-times" @click="closeNoPreReservationConfirmation" class="p-button-text"/>
              <Button label="Ok" icon="pi pi-check" @click="closeNoPreReservationConfirmation" class="p-button-text" autofocus />
          </template>
      </Dialog> 
  </div>
</template>
<script>
import { required,maxLength,minLength,numeric} from 'vuelidate/lib/validators'
import axios from'axios'
import Dropdown from 'primevue/dropdown';
import RadioButton from 'primevue/radiobutton';
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import en from '../assets/languages/PreReservation_en'
import ar from '../assets/languages/PreReservation_ar'
// @ is an alias to /src
export default {
  name: "pre-reservation",
  props:['UserId'],
  mixins:[en,ar],
  components: {Dropdown,RadioButton,Dialog,Button},
  data(){
    return{
      show:false,
      name:'',
      phone:'',
      branches:[], 
      selectedBranch:'',
      services:[], 
      selectedService:'',
      date:new Date().toISOString().slice(0,10),
      appointments:[],
      selectedAppointment:'',
      schedule:'',
      appointmentType:"1",
      ///////////////////
      noService:false,
      noAppointments:false,
      noPreReservation:false,
      //////////////////////
      lang:'',
      local:'',
    }
  },
  validations:{
    name:{
      required,
      minLen:minLength(5),
      maxLen:maxLength(255)
    },
    phone:{
      required,
      numeric,
      minLen:minLength(11),
      maxLen:maxLength(11)
    },
    selectedBranch:{
      required
    },
    selectedService:{
      required
    },
    date:{
      required
    },
    selectedAppointment:{
      required
    },
    appointmentType:{
      required
    },
  },
  created(){
    // this.UserId=3;
    this.getLanguage();
    this.getBranchByUserId(this.UserId);
  },
  watch:{
    selectedBranch(){
        this.getServiceByBranchId(this.selectedBranch.id);
        this.getServiceSchedule();
    },
    selectedService(){
      this.getServiceSchedule();
    },
    date(){
      this.getServiceSchedule();
    },
  },
  methods:{
    getLanguage(){
      localStorage.getItem('language')
      if(localStorage.getItem('language')=='en'){
        this.lang=this.en
        this.local='en'
      }
      else if(localStorage.getItem('language')=='ar'){
        this.lang=this.ar
        this.local='ar'
      }
    },
    ////////////////////////////////
    closeNoServiceConfirmation(){
      this.noService=false;
    },
    closeNoAppointmentsConfirmation(){
      this.noAppointments=false;
    },
    closeNoPreReservationConfirmation(){
      this.noPreReservation=false;
    },
    ////////////////////////////////
    getBranchByUserId(UserId){
      if(UserId){
        axios
        .post("/api/getBranchByUserId",null,
        { 
          params: {id:UserId},
          headers: {'X-localization':this.local},
        }
        )
        .then(res=>{
          if(res.data.status){
            let data=res.data.branches;
            this.branches=[...data];
            this.show=true
          }
          else
            this.$router.push({name:'NotFound'});
        })
      }
      else
        this.$router.push({name:'NotFound'});
    },
    getServiceByBranchId(branchId){
      if(branchId){
        axios
        .post("/api/getServiceByBranchId",null,
        { 
          params: {branch_id:branchId},
          headers: {'X-localization':this.local},
        }
        )
        .then(res=>{
          console.log(res.data);
          if(res.data.status){
            let data=res.data.services
            this.services=[...data]
            if(!this.services.length)
              this.noService=true
          }
        })
        .catch(err=>{
          console.log(err);
        })
      }
    },
    getServiceSchedule(){
      if(this.selectedBranch&&this.selectedService&&this.date){
        this.appointments=[];
        this.selectedAppointment='';
        this.schedule='';
        let day = new Date(this.date).getDay();
        let data ={
          branch_id :this.selectedBranch.id,
          service_id :this.selectedService.id,
          date:this.date,
          day:day
        }
        // console.log(JSON.stringify(data))
          axios
          .post("/api/getServiceSchedule",data)
          .then(res=>{
            console.log(res);
            if(res.data.status){
              let schedule = res.data.appointments.schedule
              this.schedule=schedule
              let unbookedSlot = res.data.appointments.unbookedSlot
              let customSlots=[...unbookedSlot]
              // unbookedSlot is found 
              if(customSlots.length){
                  customSlots.forEach(el => {
                    el.name=`From ${el.start} To ${el.end}`
                    this.appointments.push(el)
                  });
              }
              // unbookedSlot is empty
              else{
                this.noAppointments=true;
              }
            }
          })
          .catch(err=>{
            let status = err.response.status
            if(status===500){
              this.noPreReservation=true;
            }
          })
      }
    },
    addBooking(){
      if(!this.$v.$invalid){
        let day = new Date(this.date).getDay();
        let data = {
          name:this.name,
          phone:this.phone,
          branch_id:this.selectedService.branch_id,
          service_id:this.selectedService.id,
          date :this.date,
          day:day,
          start_time :this.selectedAppointment.start,
          end_time :this.selectedAppointment.end,
          booking_type:parseInt(this.appointmentType) ,
          price :this.schedule.price,
        }
        axios
        .post("/api/addBooking",data
        )
        .then(res=>{
          console.log(res)
          if(res.data.status){
              let booking=res.data.booking;
              this.$store.dispatch('addBooking',booking);
              this.$router.push({name: "Booking"})
              this.$v.$reset() ; 
              this.name='';
              this.phone='';
              this.branches=[];
              this.selectedBranch='';
              this.services=[];
              this.selectedService='';
              this.date='';
              this.appointments=[];
              this.selectedAppointment='';
              this.schedule='';
              this.appointmentType="1";
              //////////////////////
              this.noService=false;
              this.noAppointments=false;
              this.noPreReservation=false;
          }
        })
        .catch(err=>{
          console.log(err);
        })
      }
      else
        console.log("disable attribute is deleted from console mode")
    },
  }
};
</script>
<style lang="scss" scoped>
.pre-reservation{
  width: 100%;
  height:calc(100% - 82px);
  display: flex;
  justify-content: center;
  align-items: center;
  .form-wrapper{
    width:50%;
    margin: 0 auto;
    padding:30px;
    background-color: white;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 15px;
    .head-text{
      h6{
        text-align: center;
        font-weight: bold;
        margin: 20px auto;
      }
    }
    .form{
      display: flex;
      .form-fields{
        flex-grow: 1;
        .form-group{
          label{margin-bottom: 0px;}
          .no-drop{
            cursor: no-drop;
          }
          .p-dropdown{
            width: 100%;
          }
        }
        .form-group{
          .radio-options-wrapper{
            // height: 38px;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;
            background-color: white;
            border: 1px solid #ced4da;
            border-radius: 3px; 
            padding:0px 8px;
            .p-field-radiobutton{
              display: flex;
              align-items: center;
              margin: 8px 0px;
              label{
                font-size: 1rem;
                margin-bottom:0px !important;
                margin-left: 5px;
              } 
            }
          }
        }
      }
      .custom-class{
        flex-grow: 1;
        .img-wrapper{
            width:300px;
            height:308px;
            margin: 0 auto;
          img{
            width: 100%;
            height:100%;
          }
        }
      }
    }
    .ar{
      display: flex;
      flex-direction: row-reverse;
      .form-fields{
        flex-grow: 1;
        text-align: right;
        .form-group{
          .no-drop{
            cursor: no-drop;
          }
          .p-dropdown{
            width: 100%;
          }
        }
      }
      .custom-class{
        flex-grow: 1;
        .img-wrapper{
            width:300px;
            height:308px;
            margin: 0 auto;
          img{
            width: 100%;
            height:100%;
            -webkit-transform: scaleX(-1);
            transform: scaleX(-1);
          }
        }
      }
    }
  }
    .confirmation-content{
    display: flex;
    align-items: center;
    justify-content: center;
    .icon-wrapper{
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding:10px;
      color: #dc3545;
    }
    .text-wrapper{
      display: flex;
      justify-content: center;
      align-items: center;
      padding:10px;
      height: 100%;
    }
  }
  .ar-dialog{
    text-align: right;
    // flex-direction: row-reverse;
    .icon-wrapper{
      flex-direction: row-reverse;
    }
  }
}
/////////// slide animation ////////////
.slide-enter-active {
  -moz-transition-duration: 0.3s;
   -webkit-transition-duration: 0.3s;
   -o-transition-duration: 0.3s;
   transition-duration: 0.3s;
   -moz-transition-timing-function: ease-in;
   -webkit-transition-timing-function: ease-in;
   -o-transition-timing-function: ease-in;
   transition-timing-function: ease-in;
}

.slide-leave-active {
  -moz-transition-duration: 0.3s;
   -webkit-transition-duration: 0.3s;
   -o-transition-duration: 0.3s;
   transition-duration: 0.3s;
   -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
   -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
   -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
   transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to, .slide-leave {
  max-height: 100px;
   overflow: hidden;
}

.slide-enter, .slide-leave-to {
  overflow: hidden;
   max-height: 0;
}

@media (max-width: 767.98px) {
  .pre-reservation{
    .form-wrapper{
      width:90%;
        .head-text{
          h4{
            font-size: 20px;
          }
        }
      .form{
        .custom-class{
          display: none;
        }
      }
    }
  }
}
@media (min-width: 768px ) and (max-width:1023px) {
  .pre-reservation{
    .form-wrapper{
      width:90%;
      .form{
      .custom-class{
        .img-wrapper{
            width:200px;
            height:308px;
            margin: 0 auto;
          img{
            width: 100%;
            height:100%;
          }
        }
      }
      }
    }
  }
}
@media (min-width:1024px ) and (max-width:1440px) {
  .pre-reservation{
    .form-wrapper{
      width:80%;
      .form{
      .custom-class{
        .img-wrapper{
            width:300px;
            height:308px;
            margin: 0 auto;
          img{
            width: 100%;
            height:100%;
          }
        }
      }
      }
    }
  }
}
</style>
