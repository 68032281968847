export default{
    data(){
        return{
            en:{
                headMsg:'Make Your Pre-Reservation Now',
                nameLabel:'Name',
                phoneLabel:'Phone Number',
                branchLabel:'Branch',
                branchPlaceholder:'Choose a branch',
                serviceLabel:'Service',
                servicePlaceholder:'Choose a service',
                dateLabel:'Date',
                appointmentLabel:'Appointment',
                appointmentPlaceholder:'Choose An Appointment',
                appointmentTypeLabel:'Appointment Type',
                appointmentType:{
                    physicalLabel:'Physical In Branch',
                    onlineLabel:'Online On Zoom',
                },
                PriceLabel:'Price',
                buttonLabel:'Add Booking',
                //////// Error Msgs
                errMsgs:{
                    name:{
                        required:'Name is required',
                        minLen:'Name should be at least 5 characters',
                        maxLen:'Name cant be more than 255 charcters',
                    },
                    phone:{
                        required:'Phone number is required',
                        numeric:'Phone number should be numbers only',
                        minLen:'Phone number should be at least 11 digit',
                        maxLen:`Phone number can't be more than 11 digit`,
                    },
                    selectedService:{
                        required:'Service is required',
                    },
                    selectedBranch:{
                        required:'Branch is required',
                    },
                    date:{
                        required:'Date is required',
                    },
                    selectedAppointment:{
                        required:'Selected appointment is required',
                    },
                    appointmentType:{
                        required:'Appointment type is required',
                    },
                },
                ///////////// Dialog Msgs
                dialogMsgs:{
                    noService:'Sorry There Is No Services Available In This Branch',
                    noAppointments:'Sorry There Is No Available Appointments In This Service',
                    noPreReservation:'Sorry the Pre-Reservation Not Available For This Service Right Now'
                },
                ///////////// Dialog Buttons
                dialogbtns:{
                    okLabel:'OK',
                    closeLabel:'Close',
                }
            }
        }
    }
}